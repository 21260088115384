<template>
  <div class="question-list">
    <h2 class="title">模板管理</h2>
    <bk-list
      ref="list"
      :search-config="searchConfig"
      :button-config="buttonConfig"
      :table-config="tableConfig"
    />
  </div>
</template>

<script>
import Config from '@/config';
import { formatDate, copy } from '../../utils/util';

export default {
  data() {
    return {
      searchConfig: {
        data: [
          {
            data: [
              {
                name: 'templateName',
                label: '模板名称',
                type: 'input',
                grid: 1,
              },
            ],
          },
        ],
      },
      buttonConfig: {},
      tableConfig: {
        selection: false,
        http: {
          url: '/api/im-web/knowledge/templatePageList',
        },
        data: [
          {
            name: 'templateId',
            label: '模板编号',
          },
          {
            name: 'templateName',
            label: '模板名称',
          },

          {
            name: 'gmtCreate',
            label: '创建时间',
            render(h, params) {
              const { gmtCreate } = params.data.attrs.row;
              return <span>{formatDate(new Date(gmtCreate), 'yyyy-mm-dd HH:ii:ss')}</span>;
            },
          },
          {
            name: 'operation',
            label: '操作',
            type: 'operation',
            data: [
              {
                name: '编辑',
                label: '编辑',
                scene: 'skip',
                visible: () => this.checkPermission('KFXT_ZSKMB_ZSKMBBJ'),
                http: {
                  url: '/questionTemplateDetail',
                  targetKeys: ['id'],
                  toString: true,
                },
              },
              {
                name: '查看',
                label: '查看',
                scene: 'skip',
                http: {
                  url: '/questionTemplateShow',
                  targetKeys: ['id'],
                  toString: true,
                },
              },
              {
                name: '删除',
                label: '删除',
                scene: 'normal',
                visible: () => this.checkPermission('KFXT_ZSKMB_ZSKMBSC'),
                http: {
                  url: '/api/im-web/knowledge/deleteTemplate',
                  targetKeys: ['id'],
                  confirm: '操作后无法恢复，确认删除该问题吗',
                  toString: true,
                },
              },
              {
                name: '复制链接',
                label: '复制链接',
                callback: () => {
                  const tb = this.$refs.list;
                  const record = tb.tableData[tb.operationIndex];
                  this.copyLink(record);
                },
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.buttonConfig = {
        data: [
          {
            name: 'button1',
            label: '新增',
            selection: false,
            scene: 'skip',
            visible: () => this.checkPermission('KFXT_ZSKMB_ZSKMBBJ'),
            http: {
              url: '/questionTemplateDetail',
            },
          },
        ],
      };
    }, 500);
  },
  methods: {
    checkPermission(code) {
      return this.$store.state.permissionData.indexOf(code) !== -1;
    },

    /** @function 复制链接 */
    copyLink(record) {
      console.log(record, Config, '>>>>> 复制链接');
      const url = Config.h5TemplateUrl + record.templateId ?? '';
      copy(url)
        .then(() => {
          this.$message.success('复制成功');
        })
        .catch();
    },
  },
};
</script>
